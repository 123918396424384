import React, { useCallback, useContext, useEffect, useState } from "react";
import { FlatList, Pressable, Platform,  } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import HouseLogo from "../components/HouseLogo";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import SearchBar from "../components/SearchBarNew";
import SearchBarNew from "../components/SearchBarNew";
import { BoldText } from "../constants/styledText";
import { Feather } from "@expo/vector-icons";
import BackButton from "../components/BackButton";
import { Opportunity } from "../API";
import { API, graphqlOperation } from "aws-amplify";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import PageBackground from "../components/PageBackground";
import {User} from '../API'

// type User = {
//   id: string;
//   type: string;
//   preferredFirstName: string;
//   preferredSurname: string;
//   firstName: string;
//   surname: string;
//   Enrolments?: {
//     items: Array<{
//       groupingData1?: string;
//       groupingData3?: string;
//     }>;
//   };
// };




export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        User
        {
        title
        preferredFirstName
        surname
        firstName
        preferredSurname}
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;







const AdminBadgesAuthorise = () => {
  // const userDetailsFromUserContext = useContext(UserContext);
  // const allUserDetailsFromAllUsersContext = useContext(AllUsersContext) as User[]
//   const [filteredAndSearchedUsers, setFilteredAndSearchedUsers] = useState<User[]>(allUserDetailsFromAllUsersContext);
  
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const [typeFilter, setTypeFilter] = useState("");
  const [houseFilter, setHouseFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");


const [isLoading, setIsLoading] = useState(true)
const [opportunityDataFromServer, setOpportunityDataFromServer] = useState<Opportunity[]>([])
const [filteredAndSearchedUsers, setFilteredAndSearchedUsers] = useState<Opportunity[]>(opportunityDataFromServer);





  const fetchUserData = async () => {
    try {
      

      let nextToken = null;
      let allOpportunities = [];
  
      do {
        const response = await API.graphql(
          graphqlOperation(listOpportunities, {
            filter: {
                opportunitycategoryID: {eq: "00000000-0000-0000-0000-000000000000"},},
        
            nextToken: nextToken,
          })
        );
  
        allOpportunities = [...allOpportunities, ...response.data.listOpportunities.items];
        nextToken = response.data.listOpportunities.nextToken;
      } while (nextToken !== null);




     
        setOpportunityDataFromServer(allOpportunities);

        

        



       
       
      
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUserData();
  }, []);















  const resetFilters = () => {
    setTypeFilter("");
    setHouseFilter("");
    setYearFilter("");
    setSearchTerm("");
  };

  const applyFiltersAndSearch = useCallback(() => {
    let filtered = opportunityDataFromServer;

    if (searchTerm) {
      filtered = filtered.filter((badge) => {
        const userWhoAddedBadges = `${badge.User.preferredFirstName} ${badge.User.preferredSurname} ${badge.User.firstName} ${badge.User.surname}`.toLowerCase();
       const badgeName = badge.name.toLowerCase();
       const badgeDescription = badge.description.toLowerCase();
        return userWhoAddedBadges.includes(searchTerm.toLowerCase()) || badgeName.includes(searchTerm.toLowerCase()) || badgeDescription.includes(searchTerm.toLowerCase());
      });
    }

    // filtered = filtered.filter((user) => {
    //   const userType = user.type || "";
    //   const house = user.Enrolments?.items[0]?.groupingData1 || "";
    //   const year = user.Enrolments?.items[0]?.groupingData3 || "";

    //   const matchesType = typeFilter === "" || userType === typeFilter;
    //   const matchesHouse = houseFilter === "" || house === houseFilter;
    //   const matchesYear = yearFilter === "" || year === yearFilter;

    //   return matchesType && matchesHouse && matchesYear;
    // });

    setFilteredAndSearchedUsers(filtered);
  }, [opportunityDataFromServer, typeFilter, houseFilter, yearFilter, searchTerm]);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [applyFiltersAndSearch]);



  const RenderItem = ({ item }: { item: Opportunity }) => {


    const NavigateToBadgeDetails = () => {
      navigation.navigate("AdminBadgeDetails", { badgeID: item.id });
    };

    return (
      <Pressable
        onPress={NavigateToBadgeDetails}
        style={{
          backgroundColor: Colours[colorScheme].background,
          borderBottomColor: Colours[colorScheme].edging,
          flexDirection: "row",
          borderBottomWidth: 1,
          alignItems: "center",
          alignSelf: "center",
          width: "90%",
          height:40,
        }}>
        <View style={{ padding: 10, width: "50%", alignItems: "center", backgroundColor: "transparent" }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            {item.name}
          </Text>
        </View>
        <View style={{ padding: 10, width: "25%", alignItems: "center", backgroundColor: "transparent" }}>
          <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
            {item.oneOffDate}
          </Text>
        </View>
        <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", flexDirection: "row" }}>
          {item.User?.preferredSurname && (
            <Text numberOfLines={1} style={{ color: Colours[colorScheme].text }}>
              {item.User?.title} {item.User?.preferredSurname}
            </Text>
          )}
        </View>
      </Pressable>
    );
  };

  return (
    <PageBackground isLoading={false}>

      <View style={{ backgroundColor: "transparent", justifyContent: "center", alignItems:'center', flexDirection: "row", padding: 10 }}>
        {isLoading?<Text>Looking for badges that require authorisation...</Text>:<Text>Displaying {filteredAndSearchedUsers.length} badges that require authorisation</Text>}
      </View>
      <View
        style={{
          backgroundColor: "transparent",
          alignItems: "center",
          alignSelf: "center",
          width: "88%", //scrollbar adjustment... not accurate!
          flexDirection: "row",
        }}>

            {/* ADDITIONAL HEADER ITEMS COMMENTED OUT SO THEY CAN BE ADDED LATER IF WE WANT THIS SCREEN TO BE USED FOR MANAGING OTHER BADGES */}
        {/* <View style={{ padding: 10, width: "10%", alignItems: "center", backgroundColor: "transparent" }}> */} 
          {/* {Platform.OS === 'web'&&<FilterDropdown label="Type" value={typeFilter} onValueChange={setTypeFilter} items={["CHILD", "STAFF", "ADMIN", "ALUMNI"]} />} */}
        {/* </View> */}
        <View style={{ padding: 10, width: "100%", alignItems: "center", backgroundColor: "transparent" }}>
          <SearchBarNew
            masterData={opportunityDataFromServer}
            setFilteredData={({ searchTerm }) => {
              setSearchTerm(searchTerm);
            }}
          />
        </View>
        {/* <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", flexDirection: "row" }}> */}
        {/* {Platform.OS === 'web'&&<FilterDropdown label="House" value={houseFilter} onValueChange={setHouseFilter} items={["Bremner", "Robinson", "Tomlinson", "Trueman"]} />} */}
        {/* </View> */}
        {/* <View style={{ padding: 10, width: "25%", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", flexDirection: "row" }}> */}
        {/* {Platform.OS === 'web'&&<FilterDropdown
            label="Year"
            value={yearFilter}
            onValueChange={setYearFilter}
            items={["Year R", "Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10", "Year 11"]}
          />} */}
        {/* </View> */}
      </View>
      <View style={{ flex: 1, width: "100%", backgroundColor: Colours[colorScheme].almostBackground, borderRadius: 10, alignItems: "center", justifyContent: "center" }}>
        {isLoading?<ActivityIndicatorAnimation />:<FlatList style={{ width: "100%" }} data={filteredAndSearchedUsers} renderItem={({ item }) => <RenderItem item={item} />} keyExtractor={(item) => item.id} />}
      </View>
    </PageBackground>
  );
};

export default AdminBadgesAuthorise;

import React, { useState, useMemo } from 'react';
import { View, Text } from "../constants/themedViewAndText";
import { TouchableOpacity, ScrollView } from 'react-native';
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import ActionButton from './ActionButton';

const PupilEngagementTable = ({ users }) => {
  const [sortConfig, setSortConfig] = useState({
    key: 'surname',
    direction: 'asc'
  });
  const [startDate, setStartDate] = useState(new Date('2024-09-01'));
  const [endDate, setEndDate] = useState(new Date());
  const colorScheme = useColorScheme();

  const processUserData = (user) => {
    const displaySurname = user.preferredSurname || user.surname;
    const displayFirstName = user.preferredFirstName || user.firstName;

    // Count attendance marks within date range
    const attendanceCount = user.ClubAttendanceMarks?.items?.filter(mark => {
      const markDate = new Date(mark.recordedDate);
      return markDate >= startDate && markDate <= endDate;
    }).length || 0;

    // Process badges within date range
    const recentBadges = user.AwardedBadges?.items?.filter(badge => {
      const badgeDate = new Date(badge.dateAwarded);
      return badgeDate >= startDate && badgeDate <= endDate;
    }) || [];

    const badgeCount = recentBadges.length;
    
    const totalHousePoints = recentBadges.reduce((sum, badge) => 
      sum + (Number(badge.scoringData3Value) || 0), 0
    );

    const engagementScore = attendanceCount + totalHousePoints;

    return {
      id: user.id,
      surname: displaySurname,
      firstName: displayFirstName,
      fullName: `${displaySurname}, ${displayFirstName}`,
      attendanceCount,
      badgeCount,
      housePoints: totalHousePoints,
      engagementScore
    };
  };

  const exportToCSV = (users) => {
    const headers = ['User ID', 'First Name', 'Surname', 'Year Group', 'House', 'Coaching Group', 'Attendance Count', 'Badge Count', 'House Points', 'Engagement Score'];

    const rows = users.map(user => {
      const displaySurname = user.preferredSurname || user.surname;
      const displayFirstName = user.preferredFirstName || user.firstName;

      const yearGroup = user.Enrolments?.items[0]?.groupingData3 || "no year group data";
      const house = user.Enrolments?.items[0]?.groupingData1 || "no house data";
      const coachingGroup = user.Enrolments?.items[0]?.groupingData2 || "no coaching group data";

      // Count attendance marks within date range
      const attendanceCount = user.ClubAttendanceMarks?.items?.filter(mark => {
        const markDate = new Date(mark.recordedDate);
        return markDate >= startDate && markDate <= endDate;
      }).length || 0;

      // Process badges within date range
      const recentBadges = user.AwardedBadges?.items?.filter(badge => {
        const badgeDate = new Date(badge.dateAwarded);
        return badgeDate >= startDate && badgeDate <= endDate;
      }) || [];

      const badgeCount = recentBadges.length;

      const totalHousePoints = recentBadges.reduce((sum, badge) => 
        sum + (Number(badge.scoringData3Value) || 0), 0
      );

      const engagementScore = attendanceCount + totalHousePoints;

      return [user.id, displayFirstName, displaySurname, yearGroup, house, coachingGroup, attendanceCount, badgeCount, totalHousePoints, engagementScore];
    });

    const csvContent = [
      headers.join(','), 
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'PupilEngagement.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sortData = (data, key, direction) => {
    return [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const processedUsers = useMemo(() => 
    (users || []).map(processUserData),
    [users, startDate, endDate]
  );

  const sortedUsers = useMemo(() => 
    sortData(processedUsers, sortConfig.key, sortConfig.direction),
    [processedUsers, sortConfig]
  );

  const requestSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: 
        prevConfig.key === key && prevConfig.direction === 'asc' 
          ? 'desc' 
          : 'asc',
    }));
  };

  const HeaderCell = ({ label, sortKey, width }) => (
    <TouchableOpacity 
      onPress={() => requestSort(sortKey)}
      style={{ width }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: Colours[colorScheme].background }}>
        <Text style={{ fontWeight: 'bold', color:Colours[colorScheme].text }}>{label}</Text>
        <Text style={{ marginLeft: 4 }}>
          {sortConfig.key === sortKey ? 
            (sortConfig.direction === 'asc' ? '↑' : '↓') : 
            '↕️'}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const DataCell = ({ children, width }) => (
    <View style={{ width, padding: 10, borderBottomWidth: 1, borderBottomColor: Colours[colorScheme].edging }}>
      <Text>{children}</Text>
    </View>
  );

  const DataRow = ({ user }) => (
    <View style={{ flexDirection: 'row' }}>
      <DataCell width={200}>{user.fullName}</DataCell>
      <DataCell width={100}>{user.attendanceCount}</DataCell>
      <DataCell width={100}>{user.badgeCount}</DataCell>
      <DataCell width={100}>{user.housePoints}</DataCell>
      <DataCell width={100}>{user.engagementScore}</DataCell>
    </View>
  );

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10, gap: 10 }}>
        <View>
          <Text style={{ marginBottom: 5 }}>Start Date:</Text>
          <input
            type="date"
            value={startDate.toISOString().split('T')[0]}
            onChange={(e) => setStartDate(new Date(e.target.value))}
            style={{
              padding: 8,
              borderRadius: 4,
              borderWidth: 1,
              borderColor: Colours[colorScheme].edging,
              backgroundColor: Colours[colorScheme].background,
              color: Colours[colorScheme].text
            }}
          />
        </View>
        
        <View>
          <Text style={{ marginBottom: 5 }}>End Date:</Text>
          <input
            type="date"
            value={endDate.toISOString().split('T')[0]}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            style={{
              padding: 8,
              borderRadius: 4,
              borderWidth: 1,
              borderColor: Colours[colorScheme].edging,
              backgroundColor: Colours[colorScheme].background,
              color: Colours[colorScheme].text
            }}
          />
        </View>
        
        <ActionButton icon={'file'} text={'Export to .csv'} onPressFunction={() => exportToCSV(users)} />
      </View>

      <ScrollView horizontal>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <HeaderCell label="Name" sortKey="fullName" width={200} />
            <HeaderCell label="Club Attendance" sortKey="attendanceCount" width={100} />
            <HeaderCell label="Badges Earned" sortKey="badgeCount" width={100} />
            <HeaderCell label="House Points" sortKey="housePoints" width={100} />
            <HeaderCell label="Engagement Score" sortKey="engagementScore" width={100} />
          </View>

          <ScrollView>
            {sortedUsers.map((user) => (
              <DataRow key={user.id} user={user} />
            ))}
          </ScrollView>
        </View>
      </ScrollView>

      <View style={{ padding: 10 }}>
        <Text style={{ color: '#6b7280', fontSize: 12 }}>
          Showing data from {startDate.toLocaleDateString()} to {endDate.toLocaleDateString()}
        </Text>
      </View>
    </View>
  );
};

export default PupilEngagementTable;
import React, { useContext, useEffect, useState, useRef } from "react";
import { StyleSheet, useWindowDimensions, FlatList, ScrollView, BackHandler, Pressable, Image, Platform, Button } from "react-native";
// import {Image} from 'expo-image'
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { API, graphqlOperation } from "aws-amplify";
import TruemanIcon from "../../assets/icons/Trueman";
import HouseLogo from "../components/HouseLogo";
import { BoldText } from "../constants/styledText";
import CoachingIcon from "../../assets/icons/Coaching";
import TimelineIcon from "../../assets/icons/Timeline";
import { Storage } from "aws-amplify";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import AchievementsTabNavigator from "../navigation/achievementsTabs";
import CountingNumber from "../components/HousePoints";
import HousePointForPupil from "../components/HousePointForPupil";
import { OrganisationContext } from "../hooks/Contexts";
import { VibrateOnce } from "../functions/vibration";
import BackButton from "../components/BackButton";
import { HeadlineAchievement } from "../models";
import HeadlineAchievementListItem from "../components/HeadlineAchievementListItem";
import { whatShouldWeCallThisPerson } from "../functions/whatShouldWeCallThisPerson";
import { MaterialIcons } from "@expo/vector-icons";
import * as Print from 'expo-print';
import ActionButton from "../components/ActionButton";




export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      surname
      firstName
      preferredFirstName
      preferredSurname
      title
      type
      photoURL
      email
      isActive
      Enrolments {
        items {
          contextData1
          contextData2
          contextData3
          groupingData1
          groupingData2
          groupingData3
          scoringData1
          scoringData2
          scoringData3
        }
      }
      ClubAttendanceMarks {
        nextToken
        startedAt
        items {
          id
          recordedDate
          opportunityID
          Opportunity {
            name
            logoURL
          }
        }
      }
      AwardedBadges {
        nextToken
        startedAt
        items {
          id
          scoringData3Value
          dateAwarded
          opportunityID
          Opportunity {
            id
            isActive
            name
            aaa
            colour
            logoURL
            oneOffDate
            multipleStartDate
            multipleEndDate
            opportunitycategoryID
          }
        }
      }
      TrainingAttendance {
        nextToken
        startedAt
        items {
          id
          trainingID
          Training {
            id
            venue
            date
            name
            type
            description
          }
        }
      }
      HeadlineAchievements {
        nextToken
        startedAt
        __typename
        items {
          id
          name
          type
          logoURL
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

const TimelinesDetail = ({ route, navigation }) => {
  const { userID } = route.params;
  const colorScheme = useColorScheme();
  const { width } = useWindowDimensions();
  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const [clubAttendanceData, setClubAttendanceData] = useState([]);
  const [trainingAttendanceData, setTrainingAttendanceData] = useState([]);
  const [userData, setUserData] = useState();
  const [layoutStyle, setLayoutStyle] = useState("phone");
  const [downloadedImage, setDownloadedImage] = useState();
  const house = userData ? userData.Enrolments?.items[0]?.groupingData1 : null;
  const coach = userData ? userData.Enrolments?.items[0]?.groupingData2 : null;
  const year = userData ? userData.Enrolments?.items[0]?.groupingData3 : null;
  const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";
  // const whatShouldWeCallThisPerson = userData
  //   ? userData.type === "STAFF"
  //     ? userData.title + " " + userData.preferredSurname
  //     : userData.preferredFirstName + " " + userData.preferredSurname
  //   : "unkown";

  // const theProperNameOfTheUser = whatShouldWeCallThisPerson(userData.type, title: userData.title, preferredSurname: userData.preferredSurname, preferredFirstName: userData.preferredFirstName)

  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const { currentAcademicYearStartDate } = useContext(OrganisationContext);
  const [headlineAchievements, setHeadlineAchievements] = useState([]);



  const fetchUserData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getUser, { id: userID }));

      if (response && response.data && response.data.getUser) {
        setUserData(response.data.getUser);

        if (response.data.getUser.AwardedBadges && response.data.getUser.AwardedBadges.items) {
          setAwardedBadgesData(response.data.getUser.AwardedBadges.items);
        } else {
          setAwardedBadgesData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.HeadlineAchievements && response.data.getUser.HeadlineAchievements.items) {
          setHeadlineAchievements(response.data.getUser.HeadlineAchievements.items);
        } else {
          setHeadlineAchievements([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.ClubAttendanceMarks && response.data.getUser.ClubAttendanceMarks.items) {
          setClubAttendanceData(response.data.getUser.ClubAttendanceMarks.items);
        } else {
          setClubAttendanceData([]); // Set to an empty array if no data is available
        }

        if (response.data.getUser.TrainingAttendance && response.data.getUser.TrainingAttendance.items) {
          setTrainingAttendanceData(response.data.getUser.TrainingAttendance.items);
        } else {
          setTrainingAttendanceData([]); // Set to an empty array if no data is available
        }

        console.log("training", response.data.getUser.TrainingAttendance.items);

        if (response.data.getUser.photoURL) {
          const file = await Storage.get(response.data.getUser.photoURL, { level: "public" });
          setDownloadedImage(file);
        } else {
          setDownloadedImage(null); // Set to null if no photo URL is available
        }
      } else {
        // Handle the case when the response is empty or doesn't contain the expected data
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUserData();
  }, [userID]);

  useEffect(() => {
    if (width < 800) {
      setLayoutStyle("phone");
    } else {
      setLayoutStyle("tablet");
    }
  }, [width]);

  const ShowPhotoPopUp = () => {
    VibrateOnce();
    navigation.navigate("PhotoPopUp", { photo: downloadedImage });
  };

  const calculatePupilHousePointsTotal = () => {
    const targetDate = new Date(currentAcademicYearStartDate);
    let totalSum = 0;

    // Iterate through the array and accumulate the values
    awardedBadgesData.forEach((item) => {
      // Check if both awardedDate and scoringData fields exist for the current item
      if (item.dateAwarded !== undefined && item.scoringData3Value !== undefined) {
        // Convert awardedDate to a Date object for comparison
        const awardedDate = new Date(item.dateAwarded);

        // Check if awardedDate is after the target date
        if (awardedDate > targetDate) {
          // Add the scoringData value to the totalSum
          totalSum += item.scoringData3Value;
        }
      }
    });

    // Return the total sum
    return totalSum;
  };

  const housePointTotalForThisPupilToDisplay = calculatePupilHousePointsTotal();

  const navigateToGroupingData2List = () => {
    VibrateOnce();
    navigation.navigate("GroupingData2List", { coach });
  };

  const navigateToGroupingData3List = () => {
    VibrateOnce();

    navigation.navigate("GroupingData3List", { year });
  };

  const navigateToGroupingData1List = () => {
    VibrateOnce();
    navigation.navigate("GroupingData1List", { house });
  };

  return (
    <View style={{ flex: 1, backgroundColor: Colours[colorScheme].background, paddingTop: 10 }}>
      {/* User details */}
      {!isLoading ? (
        <>
          <View style={{ alignItems: "center", backgroundColor: Colours[colorScheme].background }}>
            <BackButton />


            <View
              style={{
                // padding: 10,
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor: Colours[colorScheme].background,
                backgroundColor: "transparent",
                flexDirection: "row",
                // flex:1,
                width: layoutStyle == "tablet" ? 640 : 320,
                // minHeight:layoutStyle == "tablet" ? 300 : 300,
                // height: layoutStyle == "tablet" ? 300 : 500,
              }}>
              <>
                <View
                  style={{
                    // flex: 1,
                    width:layoutStyle == "tablet"? 640-190: 320-130,
                    // height: "auto",
                    // height: layoutStyle == "tablet" ? 190 : 130,
                    backgroundColor: "transparent",
                    marginHorizontal: 0,
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    // :5
                  }}>



                  {/* name */}



                  <View




                    style={{
                      // flex: 1,s
                      // height:'',
                      backgroundColor: "transparent",
padding:5,
                      alignItems: "flex-start",
                      //  marginTop: layoutStyle == "tablet" ? 0 : 10
                    }}>
                    <BoldText style={{ paddingHorizontal: 5, textAlign: "left", fontSize: layoutStyle == "tablet" ? 24 : 18, color: Colours[colorScheme].textLessEmphasised }}>
                      {whatShouldWeCallThisPerson(
                        userData.type,

                        userData.title,
                        userData.preferredSurname,
                        userData.preferredFirstName
                      )}
                    </BoldText>
                  </View>

                  {/* coach and house */}
                  <View style={{ backgroundColor: "transparent" }}>
                    {year && (
                      <Pressable
                        onPress={navigateToGroupingData3List}
                        style={({ pressed }) => [
                          {
                            backgroundColor: pressed ? Colours[colorScheme].edging : Colours[colorScheme].practicallyBackground,
                            borderRadius: 10,
                            padding: 5,
                            flexDirection: "row",
                            margin: 2,
                            alignItems: "center",
                          },
                        ]}>
                        <View style={{ width: 24, height: 24, alignItems: "center", justifyContent: "center", backgroundColor: "transparent", marginRight: 5 }}>
                          <MaterialIcons name="family-restroom" size={16} color={Colours[colorScheme].textLessEmphasised} />
                        </View>
                        <Text style={{ fontSize: 14, marginHorizontal: 0, color: Colours[colorScheme].textLessEmphasised }}>{year}</Text>
                      </Pressable>
                    )}

                    {coach && (
                      <Pressable
                        onPress={navigateToGroupingData2List}
                        style={({ pressed }) => [{ backgroundColor: pressed ? Colours[colorScheme].edging : Colours[colorScheme].practicallyBackground, borderRadius: 10, padding: 5, margin: 2 }]}>
                        <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                          <View style={{ width: 24, height: 24, backgroundColor: "transparent", marginRight: 5 }}>
                            <CoachingIcon color={Colours[colorScheme].textLessEmphasised} />
                          </View>

                          <Text style={{ fontSize: 14, color: Colours[colorScheme].textLessEmphasised }}>{coach}</Text>
                        </View>
                      </Pressable>
                    )}

                    {house && (
                      <Pressable
                        onPress={navigateToGroupingData1List}
                        style={({ pressed }) => [{ backgroundColor: pressed ? Colours[colorScheme].edging : Colours[colorScheme].practicallyBackground, borderRadius: 10, padding: 5, margin: 2 }]}>
                        <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
                          <View style={{ width: 24, height: 24, backgroundColor: "transparent", marginRight: 5 }}>
                            <HouseLogo house={house} />
                          </View>
                          <Text style={{ fontSize: 14, color: Colours[colorScheme].textLessEmphasised }}>{house}</Text>
                        </View>
                      </Pressable>
                    )}
                  </View>
                </View>

                {/* photo */}
                <Pressable onPress={ShowPhotoPopUp}>
                  <View
                    style={{
                      backgroundColor: "transparent",
                      // height: layoutStyle == "tablet" ? 190 : 130,
                      width: layoutStyle == "tablet" ? 190 : 130,
                      marginHorizontal: 0,
                    }}>
                    {/* fall back icon that sits behind the photo */}

                    <View
                      style={{
                        height: layoutStyle == "tablet" ? 190 : 130,
                        width: layoutStyle == "tablet" ? 190 : 130,

                        borderRadius: 120,
                        borderColor: houseColor,
                        borderWidth: 5,
                      }}>
                      <TimelineIcon color={"#ccc"} />

                      <Image
                        source={{ uri: downloadedImage }}
                        style={{
                          height: layoutStyle == "tablet" ? 180 : 120,
                          width: layoutStyle == "tablet" ? 180 : 120,

                          borderRadius: 120,
                          // borderColor: houseColor,
                          // borderWidth: 3,

                          position: "absolute",
                        }}
                      />
                    </View>
                  </View>
                </Pressable>
              </>
            </View>


            {/* headline achievements area */}
            <View
              style={{
                height: 80,
                // width: "100%",
                // alignSelf: "center",
                // alignItems: layoutStyle == "tablet" ? "flex-start" : "center",
                alignItems: "center",
                justifyContent: "center",
                paddingVertical: 10,
                paddingHorizontal: 20,
                backgroundColor: "transparent",
                // width:'100%'
                // flex:1,
              }}>
              <ScrollView
                contentContainerStyle={{ alignItems: "center" }}
                horizontal
                style={{
                  backgroundColor: "transparent",
                  // width:'100%',
                  // flex:1,
                  // flexDirection: "row",
                  // justifyContent: "space-evenly",
                  // alignItems: "center",
                  // justifyContent: "center",
                  alignSelf: "center",
                  // flex: 1,
                  // width:'auto',
                }}>
                {housePointTotalForThisPupilToDisplay > 0 ? (
                  <View
                    style={{
                      backgroundColor: "transparent",
                      //  flex: 1
                    }}>
                    <HousePointForPupil points={housePointTotalForThisPupilToDisplay} />
                  </View>
                ) : null}

                {headlineAchievements.length > 0 ? (
                  //                   <FlatList
                  //                     data={headlineAchievements}
                  // //                     style={{width:'auto', alignSelf:'center'}}
                  // // contentContainerStyle={{alignItems:'center', justifyContent:'center'}}
                  //                     horizontal
                  //                     renderItem={({ item }) => <HeadlineAchievementListItem item={item} />}
                  //                   />

                  <View horizontal={true} style={{ flexDirection: "row" }}>
                    {headlineAchievements.map((item) => (
                      <HeadlineAchievementListItem key={item.id} item={item} />
                    ))}
                  </View>
                ) : null}
              </ScrollView>
            </View>
          </View>

          <AchievementsTabNavigator badges={awardedBadgesData} clubAttendance={clubAttendanceData} training={trainingAttendanceData} type={userData.type} />
        </>
      ) : (
        <View style={{ width: "100%", flex: 1, justifyContent: "center", backgroundColor: "transparent", alignItems: "center" }}>
          <ActivityIndicatorAnimation />
        </View>
      )}
    </View>
  );
};

export default TimelinesDetail;

const styles = StyleSheet.create({});

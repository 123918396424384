import React, { useContext, useEffect, useState } from "react";
import { Alert, FlatList, Image, Platform, Pressable, ScrollView, StyleSheet, TextInput, useWindowDimensions } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import HouseLogo from "../components/HouseLogo";
import { API, graphqlOperation } from "aws-amplify";
import {User} from '../API'
import { Storage } from "aws-amplify";
import { VibrateOnce } from "../functions/vibration";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import ActivityIndicatorAnimation from "../components/ActivityIndicator";
import { Picker } from "@react-native-picker/picker";
import TimelineIcon from "../../assets/icons/Timeline";
import RenderTextInput from "../components/RenderTextInput";
import AccountDetailsForm from "../components/AccountDetailsForm";
import EnrolmentDetailsForm from "../components/EnrolmentDetailsForms";
import BackButton from "../components/BackButton";
import RenderMultiLineTextInput from "../components/RenderMultiLineTextInput";
import PickADate from "../components/PickADate";
import SearchBar from "../components/SearchBar";
import { fetchUsersFromDatabase } from "../functions/getAllUsers";
import PhotoListItemRegisterSearch from "../components/PhotoListItemRegisterSearch";
import CollaborationIcon from "../../assets/icons/collaboration";
import InfluenceIcon from "../../assets/icons/influence";
import CommunicationIcon from "../../assets/icons/communication";
import AmbitionIcon from "../../assets/icons/ambition";
import CriticalThinkingIcon from "../../assets/icons/criticalthinking";
import InitiativeIcon from "../../assets/icons/initiative";
import CommitmentIcon from "../../assets/icons/commitment";
import SelfAwarenessIcon from "../../assets/icons/selfawareness";
import CourageIcon from "../../assets/icons/courage";
import InclusivenessIcon from "../../assets/icons/inclusiveness";
import CompassionIcon from "../../assets/icons/compassion";
import IntegrityIcon from "../../assets/icons/integrity";
import PhotoListItemRegister from "../components/PhotoListItemRegister";
import { useNavigation } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import RenderPicker from "../components/RenderPicker";
import ImageColors from 'react-native-image-colors'
import DynamicBadge from "../components/DynamicBadge";
import CollapsibleSection from "../components/CollapseableSection";
import PageBackground from "../components/PageBackground";
export const updateOpportunity = /* GraphQL */ `
  mutation UpdateOpportunity($input: UpdateOpportunityInput!, $condition: ModelOpportunityConditionInput) {
    updateOpportunity(input: $input, condition: $condition) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Mappings {
        nextToken
        startedAt
        __typename
      }
      OpportunityTagMapping {
        nextToken
        startedAt
        __typename
      }
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      AwardedBadges {
        nextToken
        startedAt
        __typename
      }
      OpportunityAvailabilities {
        nextToken
        startedAt
        __typename
      }
      availableTo
      year
      term
      ClubAttendanceMarks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      isActive
      name
      aaa
      description
      logoURL
      location
      colour
      engagementLevel
      regularMonday
      regularTuesday
      regularWednesday
      regularThursday
      regularFriday
      regularStartTime
      regularEndTime
      dateType
      oneOffDate
      regularStartDat
      regularEndDate
      multipleStartDate
      multipleEndDate
      totalHours
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Mappings {
        nextToken
        startedAt
        __typename
      }
      OpportunityTagMapping {
        nextToken
        startedAt
        __typename
      }
      opportunitycategoryID
      OpportunityCategory {
        id
        name
        aaa
        isActive
        logoWhiteURI
        logoColourURI
        logoGreyURI
        colour
        organisationID
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      AwardedBadges {
        items {
          id
          _version
          dateAwarded
          scoringData1Value
          scoringData2Value
          scoringData3Value
          groupingData1Value
          groupingData2Value
          groupingData3Value
          userID
          User {
            id
            dateOfBirth
            email
            firstName
            gender
            isActive
            photoURL
            preferredFirstName
            preferredSurname
            surname
            title
            type
            Enrolments {
              items {
                groupingData1
                groupingData2
                groupingData3
              }
            }
          }
        }
        nextToken
        startedAt
        __typename
      }
      OpportunityAvailabilities {
        nextToken
        startedAt
        __typename
      }
      availableTo
      year
      term
      ClubAttendanceMarks {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

export const updateAwardedBadge = /* GraphQL */ `
  mutation UpdateAwardedBadge($input: UpdateAwardedBadgeInput!, $condition: ModelAwardedBadgeConditionInput) {
    updateAwardedBadge(input: $input, condition: $condition) {
      id
      dateAwarded
      scoringData1Value
      scoringData2Value
      scoringData3Value
      groupingData1Value
      groupingData2Value
      groupingData3Value
      opportunityID
      Opportunity {
        id
        isActive
        name
        aaa
        description
        logoURL
        location
        colour
        engagementLevel
        regularMonday
        regularTuesday
        regularWednesday
        regularThursday
        regularFriday
        regularStartTime
        regularEndTime
        dateType
        oneOffDate
        regularStartDat
        regularEndDate
        multipleStartDate
        multipleEndDate
        totalHours
        userID
        opportunitycategoryID
        availableTo
        year
        term
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      userID
      User {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        aaa
        title
        type
        photoURL
        dateOfBirth
        email
        gender
        isActive
        pushToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;

const AdminBadgeDetails = ({ route }) => {
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext) as User[]
  const { badgeID } = route.params;
  const [selectedUserId, setSelectedUserId] = useState<string | null>('Select a member of staff');
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const [badgeDataFromServer, setBadgeDataFromServer] = useState({});
  const [userData, setUserData] = useState({});
  const [downloadedImage, setDownloadedImage] = useState();
  const [localImageUri, setLocalImageUri] = useState("");
  const [shouldUploadImage, setShouldUploadImage] = useState(false);
  const [imageToDisplay, setImageToDisplay] = useState("");
  // const house = userData ? userData.Enrolments?.items[0]?.groupingData1 : null;
  // const coach = userData ? userData.Enrolments?.items[0]?.groupingData2 : null;
  // const year = userData ? userData.Enrolments?.items[0]?.groupingData3 : null;
  // const houseColor = house ? Colours.palette[house.toLowerCase()] : "grey";
  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const [clubAttendanceData, setClubAttendanceData] = useState([]);
  const [trainingAttendanceData, setTrainingAttendanceData] = useState([]);
  const [headlineAchievements, setHeadlineAchievements] = useState([]);
  const [enrolmentData, setEnrolmentData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const [isAccountSectionVisible, setIsAccountSectionVisible] = useState(true);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [formData, setFormData] = useState({}); // State to track form data
  const [isUserDirty, setIsUserDirty] = useState(false); // State to track if there are unsaved changes
  const [isEnrolmentDirty, setIsEnrolmentDirty] = useState(false); // State to track if there are unsaved changes
  const [isBadgeDetailsSectionVisible, setIsBadgeDetailsSectionVisible] = useState(true);
  const [isAwardDetailsSectionVisible, setIsAwardDetailsSectionVisible] = useState(true);
  const [isSearchAndSuggestionSectionVisible, setIsSearchAndSuggestionSectionVisible] = useState(false);
  const [isPupilsPresentSectionVisible, setIsPupilsPresentSectionVisible] = useState(false);
  const [isEnrolmentSectionVisible, setIsEnrolmentSectionVisible] = useState(true);
  const [nameOfBadge, setNameOfBadge] = useState<string>("");
  const [descriptionOfBadge, setDescriptionOfBadge] = useState<string>("");
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  //   const today = new Date();
  //   const formattedDateDefault = today.toISOString().slice(0, 10);
  const [date, setDate] = useState<string>("");
  const [filteredWithoutAdded, setFilteredWithoutAdded] = useState([]);
  const [anyEditsMade, setAnyEditsMade] = useState(false);
  const { width } = useWindowDimensions();
  const numberOfColumns = width > 1700 ? 5 : width > 1330 ? 4 : width > 1000 ? 3 : width > 680 ? 2 : 1;
  const [selectedCharacterAttribute, setSelectedCharacterAttributes] = useState<string[]>([]); // Tracks all selected items
  const [characterAttributeStates, setCharacterAttributeStates] = useState<{ [key: string]: boolean }>({}); // Dynamic object for toggle state
  const [downloadedImageURI, setDownloadedImageURI] = useState<string | null>(null); //state variable for imageURI
  const [engagementLevel, setEngagementLevel] = useState();
  const [category, setCategory] = useState();
  const [dominantColour, setDominantColour] = useState("#000000");

  
const [badgeCreator, setBadgeCreator] = useState("Select a member of staff!")


  const EngagementLevelsAvailable = [
    {
      label: "1: School activity",
      value: 1,
    },
    {
      label: "2: Optional school activity during curriculum time",
      value: 2,
    },
    {
      label: "3: Optional school activity outside of curriculum time",
      value: 3,
    },
    {
      label: "4: Optional activity outside of school",
      value: 4,
    },
  ];

  const BadgeCategoriesAvailable = [
    {
      label: "Ambassador",
      value: "37dbe22c-c4e2-4f8b-b9ee-939279e56487",
    },
    {
      label: "Work experience",
      value: "384e5604-4516-49c7-9b58-8f02f1ea0ba0",
    },
    {
      label: "Flexible Learning",
      value: "9b07f29f-9188-492a-9536-09ed59b3f224",
    },
    {
      label: "Volunteering",
      value: "b7da20c0-9e36-4c70-9cbf-651080193040",
    },
    {
      label: "Visits",
      value: "71ee8a14-7ab4-45c4-827c-27064b2417e0",
    },
    {
      label: "Independent Learning",
      value: "0aeacce3-6316-4eb6-9ab1-fba086f30ce8",
    },
  ];

  const iconComponents = {
    Collaboration: CollaborationIcon,
    Influence: InfluenceIcon,
    Communication: CommunicationIcon,
    Ambition: AmbitionIcon,
    "Critical-thinking": CriticalThinkingIcon,
    Initiative: InitiativeIcon,
    Commitment: CommitmentIcon,
    "Self-awareness": SelfAwarenessIcon,
    Courage: CourageIcon,
    Inclusiveness: InclusivenessIcon,
    Compassion: CompassionIcon,
    Integrity: IntegrityIcon,
  };

  const characterAttributes = [
    "Collaboration",
    "Influence",
    "Communication",
    "Ambition",
    "Critical-thinking",
    "Initiative",
    "Commitment",
    "Self-awareness",
    "Courage",
    "Inclusiveness",
    "Compassion",
    "Integrity",
  ];
  const CHARACTER_ATTRIBUTE_IDS = {
    Integrity: "lca01",
    Inclusiveness: "lca02",
    Compassion: "lca03",
    Influence: "lca04",
    Collaboration: "lca05",
    Communication: "lca06",
    Commitment: "lca07",
    Courage: "lca08",
    "Self-awareness": "lca09",
    Ambition: "lca10",
    "Critical-thinking": "lca11",
    Initiative: "lca12",
  } as const;

  // useEffect(() => {
  //   const updateForm = async () => {
  //     setNameOfBadge(badgeDataFromServer.name);
  //     setDescriptionOfBadge(badgeDataFromServer.description);

  //     // Handle date initialization from server data
  //     if (badgeDataFromServer.oneOffDate) {
  //       // Ensure the date is in the correct format (YYYY-MM-DD)
  //       const formattedDate = new Date(badgeDataFromServer.oneOffDate).toISOString().split("T")[0];
  //       setDate(formattedDate);
  //     } else {
  //       // Set default date to today if no date from server
  //       const today = new Date();
  //       const formattedToday = today.toISOString().split("T")[0];
  //       setDate(formattedToday);
  //     }

  //     setIsLoading(false);
  //   };

  //   updateForm();
  // }, [badgeDataFromServer]);

  // Date change handler
  const handleDateChange = (newDate: string) => {
    setDate(newDate);
    setAnyEditsMade(true);
  };


  useEffect(() => {
    const getDominantColour = async (
      // imageToScan:string
    ) => {


      console.warn('about to get colour for:', imageToDisplay)

      const result = await ImageColors.getColors(
        // imageToScan
        imageToDisplay

        , {
          fallback: '#6e8379',
          // cache: true,
          key: 'unique_key',
        })
      console.warn(result)
      setDominantColour(result.vibrant)
    }



    if(imageToDisplay){
    getDominantColour()
    }
  }, [imageToDisplay])



  const filterAndSortStaffOrAdmin = (allUsers: User[]): User[] => {
    return allUsers
      .filter(user => user.type === 'STAFF' || user.type === 'ADMIN') // Filter for STAFF or ADMIN
      .sort((a, b) => {
        if (a.preferredSurname < b.preferredSurname) return -1;
        if (a.preferredSurname > b.preferredSurname) return 1;
        return 0; // Sort alphabetically by preferredSurname
      });
  };
  
  const sortedStaffOrAdminUsers = filterAndSortStaffOrAdmin(allUserDetailsFromAllUsersContext);
  console.log(sortedStaffOrAdminUsers);


  const fetchBadgeData = async () => {
    try {
      const response = await API.graphql(graphqlOperation(getOpportunity, { id: badgeID }));
      console.log("response", response);

      //UPDATE THE LIST OF USERS THAT GOT THE BADGE
      if (response && response.data && response.data.getOpportunity) {
        setBadgeDataFromServer(response.data.getOpportunity);
        if (response.data.getOpportunity.AwardedBadges.items.length > 0) {
          console.log("some children achieved this badge");
          const awardedBadges = response.data.getOpportunity.AwardedBadges.items;
          const extractedUserData = awardedBadges.map((item) => item.User);
          setFilteredAttendance(extractedUserData);
          setAwardedBadgesData(awardedBadges);
          console.log("filteredAttendance", filteredAttendance);
        } else {
          console.log("no one got this badge");
        }
      } else {
        // Handle the case when the response is empty or doesn't contain the expected data
      }

      //UPDATE THE NAME AND DESCRIPTION
      setNameOfBadge(response.data.getOpportunity.name);
      setDescriptionOfBadge(response.data.getOpportunity.description || "");
      setBadgeCreator(
        `${response.data.getOpportunity.User.preferredFirstName || 'Unknown first name'} ${response.data.getOpportunity.User.preferredSurname || 'Unknown surname'}`
      );
      setSelectedUserId(response.data.getOpportunity.userID)
      // setCategory(response.data.getOpportunity.opportunitycategoryID || null)
      

      //UPDATE THE DATE
      // Handle date initialization from server data
      if (response.data.getOpportunity.oneOffDate) {
        // Ensure the date is in the correct format (YYYY-MM-DD)
        const formattedDate = new Date(response.data.getOpportunity.oneOffDate).toISOString().split("T")[0];
        setDate(formattedDate);
      } else {
        // Set default date to today if no date from server
        const today = new Date();
        const formattedToday = today.toISOString().split("T")[0];
        setDate(formattedToday);
      }

      //GET THE IMAGE FROM THE S3 BUCKET USING THE LOGOURI
      if (response.data.getOpportunity.logoURL) {
        const file = await Storage.get(response.data.getOpportunity.logoURL, {
          level: "public",
        }); //get file using query data

        setDownloadedImageURI(file); //store imageURI in state variable to be used elsewhere on the page
        setImageToDisplay(file);
      } else {
        console.log("no logoURL available.");
      }



//IF THERE IS A CATEGORY AND ENGAGEMENT LEVEL ALREADY, UPDATE THESE

if(response.data.getOpportunity.engagementLevel) {
  const selectedEngagementLevel = EngagementLevelsAvailable.find(
    level => level.value === response.data.getOpportunity.engagementLevel
  );
  setEngagementLevel(selectedEngagementLevel);
}

if(response.data.getOpportunity.opportunitycategoryID) {
  const selectedCategory = BadgeCategoriesAvailable.find(
    category => category.value === response.data.getOpportunity.opportunitycategoryID
  );
  setCategory(selectedCategory);
}
















    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setIsLoading(false); // Mark the loading as complete, whether success or error
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchBadgeData();
  }, [badgeID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersList = await fetchUsersFromDatabase();

        const allUsersFilteredByType = usersList.filter((user) => user.type === "CHILD");

        setFilteredData(allUsersFilteredByType);
        setMasterData(allUsersFilteredByType);
      } catch (err) {
        console.log(err.message); // Handle error
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const SaveButtonListFooter = () => {
    return (
      <View
        style={{
          backgroundColor: "transparent",
          //  width:'100%',
          alignItems: "center",
        }}>
        {isFormComplete() && <ActionButton icon={"check-square"} text={"Award this badge"} onPressFunction={SaveRegister} />}
      </View>
    );
  };

  // Function to check if all required fields are filled
  const isFormComplete = () => {
    return (
      nameOfBadge !== "" &&
      descriptionOfBadge !== "" &&
      date !== "" &&
      engagementLevel?.value != null &&
      category?.value != null &&
      selectedUserId !== "Select a member of staff" // Ensure selectedUserId is not the placeholder value
    );
  };

  const handleAddItem = (itemId) => {
    // Check if the item with itemId is already in filteredAttendance
    const isItemExist = filteredAttendance.some((item) => item.id === itemId);

    // If the item doesn't exist in filteredAttendance, add it
    if (!isItemExist) {
      // Find the item from allUserDetailsFromAllUsersContext using itemId
      const newItem = filteredData.find((user) => user.id === itemId);
      // Log the entire record being added
      console.log("Record being added:", newItem);
      // Update the state with the new item added to filteredAttendance
      setFilteredAttendance([...filteredAttendance, newItem]);
      setAnyEditsMade(true);
      setIsPupilsPresentSectionVisible(true);
    }
  };


  useEffect(() => {
    const searchFilteredWithoutAdded = filteredData.filter((user) => !filteredAttendance.some((attendant) => attendant.id === user.id));
    setFilteredWithoutAdded(searchFilteredWithoutAdded);
  }, [filteredAttendance, filteredData]);












  const UserPicker = ({ users }: { users: User[] }) => {

  
    // Filter and sort the users for the picker
    const staffOrAdminUsers = filterAndSortStaffOrAdmin(users);
  
    return (
     

        <Picker
        itemStyle={
          {
            fontSize: 16, fontFamily: "avenir-next",color: Colours[colorScheme].text,
          }
        }
          style={{
            borderWidth: 1,
            margin: 10,
            padding: 10,
            borderRadius: 8,
            fontSize: 16,
            fontFamily: "avenir-next",
            width: 280,
            outlineStyle: "none",
            color: Colours[colorScheme].textLessEmphasised,
            backgroundColor: Colours[colorScheme].background,
            borderColor: Colours[colorScheme].edging,
          }}
          selectedValue={selectedUserId}
          onValueChange={(itemValue) => setSelectedUserId(itemValue)}
          
          >
          <Picker.Item label="Select a member of staff" value={'Select a member of staff'} />
          {staffOrAdminUsers.map((user) => (
            <Picker.Item
              key={user.id}
              label={`${user.preferredFirstName} ${user.preferredSurname}`}
              value={user.id}
            />

          ))}
        </Picker>






       
     
    );
  };


























  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      allowsMultipleSelection:false,
      aspect: [4, 3],
      quality: 1,
    });
    console.log(result);
    if (!result.canceled) {
      setLocalImageUri(result.assets[0].uri);
      setImageToDisplay(result.assets[0].uri);
      setShouldUploadImage(true);
      // getDominantColour(imageToDisplay)
    }
  };

  const SaveRegister = async () => {







    console.log("save button pressed");

    //minimise the form to prevent more clicks
    setIsPupilsPresentSectionVisible(false);
    setIsBadgeDetailsSectionVisible(false);
    setIsSearchAndSuggestionSectionVisible(false);
    setIsAwardDetailsSectionVisible(false);

    //check to see if the form has been completed
    if (isFormComplete()) {




      if (shouldUploadImage) {
        //upload the image
  
        const uploadImage = async () => {
          const badgeNameWithOutSpaces = nameOfBadge.replace(/\s+/g, '');
          const generatedImageFileName = 'organisation-assets/carrmanor/opportunityLogos/OppIcon' + Date.now() + badgeNameWithOutSpaces + Math.floor(Math.random() * 1000001) + ".png"
  
          try {
            console.warn('IMAGE IN UPLOADIMAGE:', imageToDisplay)
            const response = await fetch(imageToDisplay)
            const blob = await response.blob()
            const s3ResponseKey = await Storage.put(generatedImageFileName, blob)
            const newKey = s3ResponseKey.key
  
            console.warn('s3response = ', s3ResponseKey.key)
            console.warn(badgeDataFromServer.id, dominantColour, newKey)
            const stuffToUpload = {
              id: badgeDataFromServer.id,
              logoURL: newKey,
              colour: dominantColour,
              _version: badgeDataFromServer._version,
            }

            await API.graphql({
              query: updateOpportunity,
              variables: {
                input: stuffToUpload
              },
            });
 
            return
          } catch (e) {
            Alert.alert('error uploading file')
          }
  
  
        }

        uploadImage();
      }
































      try {
        
        const input = {
          id: badgeDataFromServer.id,
          isActive: true,
          name: nameOfBadge,
          description: descriptionOfBadge,
          engagementLevel: engagementLevel.value,
          dateType: "ONEOFFDATE",
          oneOffDate: date,
          opportunitycategoryID: category.value,
          totalHours: 1,
          _version: badgeDataFromServer._version,
          userID: selectedUserId,
        };

        // Correct way to pass the input to the mutation
        const response = await API.graphql(graphqlOperation(updateOpportunity, { input }));

        console.log("response to opportunity", response);

        const getHousePoints = (level) => {
          switch (level) {
            case 1:
              return 1;
            case 2:
              return 5;
            case 3:
              return 10;
            case 4:
              return 20;
            default:
              return 0;
          }
        };

        const housePoints = getHousePoints(engagementLevel?.value);

        let counter = 0;

        // Iterate through each item in awardedBadgesData array
        for (const item of awardedBadgesData) {
          counter = counter + 1;

          console.log("item", item);

          const input = {
            id: item.id,
            dateAwarded: date,
            scoringData3Value: housePoints,
          
            _version: item._version,
          };

          console.log("awardedBadgeInput", input);

          const response = await API.graphql(graphqlOperation(updateAwardedBadge, { input }));

          console.log("response", response);
        }

        setNameOfBadge("");
        setDescriptionOfBadge("");
        setDate("");
        setCharacterAttributeStates({});
        setFilteredAttendance([]);
        setAwardedBadgesData([]);
        setEngagementLevel(undefined);
        setCategory(undefined);

        // Generate the alert message
        let alertMessage = `${counter} pupils have been awarded this badge`;

        if (Platform.OS === "web") {
          alert(alertMessage);
        } else {
          Alert.alert(alertMessage);
        }

        NavigateToHomeScreen();

        // // Handle success case
        // if (response.data.updateOpportunity) {
        //   // Add success handling here
        //   console.log("Successfully updated opportunity");
        // }
      } catch (error) {
        // Add error handling
        console.error("Error updating opportunity:", error);
        // You might want to show an error message to the user here
      }
    } else {
      console.log("FORM NOT COMPLETE");
    }
  };

  const handleDeleteItem = (itemId) => {
    console.log(itemId);

    // Generate the alert message
    let alertMessage = `Sorry! This feature hasn't been finished yet.`;

    if (Platform.OS === "web") {
      alert(alertMessage);
    } else {
      Alert.alert(alertMessage);
    }

    // if (itemId) {
    //   const updatedAttendance = filteredAttendance.filter((item) => item.id !== itemId);
    //   setFilteredAttendance(updatedAttendance);

    //   setAnyEditsMade(true);
    // } else {
    //   console.log("Item not found");
    // }
  };



  const NavigateToHomeScreen = () => {
    navigation.navigate("Home");
  };
  




  return (
    <PageBackground isLoading={isLoading}>

      <CollapsibleSection title={"Basic badge details"} isVisible={isBadgeDetailsSectionVisible} onToggle={() => setIsBadgeDetailsSectionVisible(!isBadgeDetailsSectionVisible)}>
        
      <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
          <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
            <Text>Creator:</Text>
          </View>
          <View style={{ backgroundColor: "transparent", width: "50%" }}>
            <View
              style={{
                padding: 10,
                margin: 10,
                flexDirection: "row",
                backgroundColor: Colours[colorScheme].almostBackground,
                borderColor: Colours.palette.logoGreen,
                borderWidth: 1,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
              }}>
            
              <UserPicker users={allUserDetailsFromAllUsersContext} />
          



       
   
            </View>
          </View>
        </View>
        
        <RenderTextInput label={"Name of badge"} value={nameOfBadge} onChange={setNameOfBadge} />
        <RenderMultiLineTextInput label={"Description of activity"} value={descriptionOfBadge} onChange={setDescriptionOfBadge} />

        <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
          <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
            <Text>Date:</Text>
          </View>
          <View style={{ backgroundColor: "transparent", width: "50%" }}>
            <View
              style={{
                padding: 10,
                margin: 10,
                flexDirection: "row",
                backgroundColor: Colours[colorScheme].almostBackground,
                borderColor: Colours.palette.logoGreen,
                borderWidth: 1,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <PickADate date={date} setDate={handleDateChange} />
            </View>
          </View>
        </View>
      </CollapsibleSection>

      {/* <CollapsibleSection title={"Add some pupils"} isVisible={isSearchAndSuggestionSectionVisible} onToggle={() => setIsSearchAndSuggestionSectionVisible(!isSearchAndSuggestionSectionVisible)}>
        <SearchBar masterData={masterData} setFilteredData={setFilteredData} filteredData={filteredData} />
        <View style={{ backgroundColor: "transparent", height: 150 }}>
          {isLoading ? (
            <View style={{ backgroundColor: "transparent", alignItems: "center", flex: 1, width: "100%", justifyContent: "center" }}>
              <ActivityIndicatorAnimation />
            </View>
          ) : (
            <FlatList data={filteredWithoutAdded} horizontal renderItem={({ item }) => <PhotoListItemRegisterSearch item={item} onPressToAdd={handleAddItem} />} />
          )}
        </View>
      </CollapsibleSection> */}

      <CollapsibleSection
        title={`${filteredAttendance.length} ${filteredAttendance.length === 1 ? "pupil" : "pupils"} will be awarded this badge`}
        isVisible={isPupilsPresentSectionVisible}
        onToggle={() => setIsPupilsPresentSectionVisible(!isPupilsPresentSectionVisible)}>
        {filteredAttendance.length === 0 ? <Text style={{ color: Colours[colorScheme].text, padding: 5 }}>No one is here. 🙁</Text> : null}

        <FlatList
          data={filteredAttendance}
          renderItem={({ item }) => <PhotoListItemRegister item={item} onPressDelete={handleDeleteItem} />}
          numColumns={numberOfColumns}
          // scrollEnabled={false}
          style={numberOfColumns > 1 ? null : { alignSelf: "center" }}
          // columnWrapperStyle={numberOfColumns > 1 ? { justifyContent:'flex-start' } : null}
          key={numberOfColumns}
          ListHeaderComponent={<SaveButtonListFooter />}
        />
      </CollapsibleSection>

      <CollapsibleSection title={"Additional badge details"} isVisible={isAwardDetailsSectionVisible} onToggle={() => setIsAwardDetailsSectionVisible(!isAwardDetailsSectionVisible)}>
        <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
          <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
            <Text>Icon:</Text>
          </View>
          <View style={{ backgroundColor: "transparent", width: "50%" }}>
            <View
              style={{
                padding: 10,
                margin: 10,
                flexDirection: "column",
                backgroundColor: Colours[colorScheme].almostBackground,
                borderColor: Colours.palette.logoGreen,
                borderWidth: 1,
                borderRadius: 8,
                alignItems: "center",
              }}>
      
              <DynamicBadge size={200} edgeColour={dominantColour} engagementLevel={3} logo={imageToDisplay} />

              <ActionButton icon={"image"} text={"Browse images"} onPressFunction={pickImage} />
            </View>
          </View>
        </View>

        <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
          <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
            <Text>Engagement Level:</Text>
          </View>
          <View style={{ backgroundColor: "transparent", width: "50%" }}>
            <View
              style={{
                padding: 10,
                margin: 10,
                flexDirection: "row",
                backgroundColor: Colours[colorScheme].almostBackground,
                borderColor: Colours.palette.logoGreen,
                borderWidth: 1,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <RenderPicker selectedItem={engagementLevel} setSelectedItem={setEngagementLevel} items={EngagementLevelsAvailable} itemLabel={(item) => item.label} />
            </View>
          </View>
        </View>

        <View style={{ backgroundColor: "transparent", flexDirection: "row", alignItems: "center" }}>
          <View style={{ backgroundColor: "transparent", width: "50%", alignItems: "flex-end" }}>
            <Text>Category:</Text>
          </View>
          <View style={{ backgroundColor: "transparent", width: "50%" }}>
            <View
              style={{
                padding: 10,
                margin: 10,
                flexDirection: "row",
                backgroundColor: Colours[colorScheme].almostBackground,
                borderColor: Colours.palette.logoGreen,
                borderWidth: 1,
                borderRadius: 8,
                alignItems: "center",
                justifyContent: "center",
              }}>
              <RenderPicker selectedItem={category} setSelectedItem={setCategory} items={BadgeCategoriesAvailable} itemLabel={(item) => item.label} />
            </View>
          </View>
        </View>

        <SaveButtonListFooter />
      </CollapsibleSection>
    </PageBackground>
  );
};

export default AdminBadgeDetails;

const styles = StyleSheet.create({});

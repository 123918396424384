import React, { useContext } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { AllUsersContext, UserContext } from "../hooks/Contexts";
import ActionButton from "../components/ActionButton";
import { useNavigation } from "@react-navigation/native";
import BackButton from "../components/BackButton";
import { checkDataIntegrity, findAwardedBadgesWithoutOpportunities } from "../functions/dataValidation";

const AdminRoot = () => {
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  const colorScheme = useColorScheme();
  const navigation = useNavigation()
  const type = userDetailsFromUserContext.type

  const NavigateToAdminUsers = () => {
    navigation.navigate("AdminUsers", { });
  };

  const NavigateToAwardNewBadge = () => {
    navigation.navigate("AwardNewBadge", { });
  };

  const NavigateToAdminBadgesAuthorise = () => {
    navigation.navigate("AdminBadgesAuthorise", { });
  };
  const NavigateToAdminBadges = () => {
    navigation.navigate("AdminBadges", { });
  };
  const NavigateToAdminActivities = () => {
    navigation.navigate("AdminActivities", { });
  };
  const NavigateToAdminDataIntegrityCheck = () => {
    navigation.navigate("AdminDataIntegrityCheck", { });
  };

  const NavigateToAdminAnalaysisPupilEngagement = () => {
    navigation.navigate("AdminAnalysisPupilEngagement", { });
  };

  return (
    
    <View style={{backgroundColor:Colours[colorScheme].background, flex:1,}}>
<View style={{ backgroundColor: Colours[colorScheme].background, height:40 }}>
        <BackButton />
      </View>


      <View style={{flex:1, width:'100%',backgroundColor: Colours[colorScheme].almostBackground, borderRadius:10,alignItems:'center', justifyContent:'center' }}>
  

{type === 'ADMIN'?<>
      <ActionButton icon={'users'} text={'Users'} onPressFunction={NavigateToAdminUsers} />
      <ActionButton icon={'plus-circle'} text={'Award new badge'} onPressFunction={NavigateToAwardNewBadge} />
      <ActionButton icon={'check'} text={'Authorise badges'} onPressFunction={NavigateToAdminBadgesAuthorise} />
      <ActionButton icon={'award'} text={'All badges'} onPressFunction={NavigateToAdminBadges} />
      <ActionButton icon={'dribbble'} text={'Flexible Learning'} onPressFunction={NavigateToAdminActivities} />
      <ActionButton icon={'tool'} text={'Data integrity check'} onPressFunction={NavigateToAdminDataIntegrityCheck} />
      <ActionButton icon={'bar-chart-2'} text={'Engagement Analysis'} onPressFunction={NavigateToAdminAnalaysisPupilEngagement} />
</>:null}


      </View>
    </View>
  );
};

export default AdminRoot;

const styles = StyleSheet.create({});

import React, { useEffect, useState } from 'react'
import { StyleSheet} from 'react-native'
import { View, Text} from "../constants/themedViewAndText";
import PageBackground from '../components/PageBackground';
import CollapsibleSection from '../components/CollapseableSection';
import { API, graphqlOperation } from 'aws-amplify';

export const listClubAttendances = /* GraphQL */ `
  query ListClubAttendances(
    $filter: ModelClubAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClubAttendances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendanceMark
        recordedDate
        userID
        opportunityID
        Opportunity {
            id
            isActive
            name
            aaa
            colour
            logoURL
            oneOffDate
            multipleStartDate
            multipleEndDate
            opportunitycategoryID
          }
        recordedByID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const listAwardedBadges = /* GraphQL */ `
  query ListAwardedBadges(
    $filter: ModelAwardedBadgeFilterInput
    $nextToken: String
  ) {
    listAwardedBadges(filter: $filter, limit: 99999999, nextToken: $nextToken) {
      items {
        id
        dateAwarded
        scoringData1Value
        scoringData2Value
        scoringData3Value
        groupingData1Value
        groupingData2Value
        groupingData3Value
        opportunityID
        Opportunity {
            id
            isActive
            name
            aaa
            colour
            logoURL
            oneOffDate
            multipleStartDate
            multipleEndDate
            opportunitycategoryID
          }
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

const DataIntegrityCheck = () => {
  const [state, setState] = useState({
      isLoading: true,
      isOpportunitySectionVisible: true,
      isAttendanceSectionVisible: true,
      isAttendanceOpportunitySectionVisible: true,
      opportunityData: null,
      duplicateAttendanceData: null,
      missingOpportunityAttendances: null,
      error: null
  });
  const [missingAttendances, setMissingAttendances] = useState([]);  // Initialize as empty array instead of null

  useEffect(() => {
      fetchAllData();
  }, []);

  const fetchAllData = async () => {
    try {
        const [opportunityResult, duplicatesResult, missingOpportunityResult, missingAttendanceResult] = await Promise.all([
            findAwardedBadgesWithoutOpportunities(),
            findDuplicateAttendances(),
            findAttendancesWithoutOpportunities(),
            findMissingAttendanceIDs()
        ]);

        setState(prev => ({
            ...prev,
            opportunityData: opportunityResult,
            duplicateAttendanceData: duplicatesResult,
            missingOpportunityAttendances: missingOpportunityResult,
            isLoading: false
        }));
        setMissingAttendances(missingAttendanceResult || []); // Ensure we never set null
    } catch (error) {
        console.error('Error fetching data:', error);
        setState(prev => ({
            ...prev,
            error: error.message,
            isLoading: false
        }));
    }
};

  async function findAttendancesWithoutOpportunities() {
    let nextToken = null;
    const allAttendances = [];
  
    do {
      const attendanceResult = await API.graphql(
        graphqlOperation(listClubAttendances, {
          nextToken,
          limit: 1000
        })
      );
  
      const currentPage = attendanceResult.data.listClubAttendances;
      allAttendances.push(...currentPage.items);
      nextToken = currentPage.nextToken;
    } while (nextToken);
  
    const attendancesWithMissingOpportunities = allAttendances.filter(
      attendance => !attendance._deleted && attendance.opportunityID && !attendance.Opportunity
    );
  
    const missingOpportunityIDs = [...new Set(
      attendancesWithMissingOpportunities.map(attendance => attendance.opportunityID)
    )];
  
    const summary = missingOpportunityIDs.map(opportunityID => {
      const relatedAttendances = attendancesWithMissingOpportunities.filter(
        attendance => attendance.opportunityID === opportunityID
      );
  
      return {
        opportunityID,
        count: relatedAttendances.length,
        userIDs: [...new Set(relatedAttendances.map(att => att.userID))],
        attendanceDetails: relatedAttendances.map(att => ({
          id: att.id,
          userID: att.userID,
          recordedDate: att.recordedDate,
          attendanceMark: att.attendanceMark
        }))
      };
    });
  
    return {
      totalMissingOpportunityIDs: missingOpportunityIDs.length,
      totalAffectedAttendances: attendancesWithMissingOpportunities.length,
      details: summary
    };
  }

  async function findDuplicateAttendances() {
    let nextToken = null;
    const allAttendances = [];
  
    do {
      const attendanceResult = await API.graphql(
        graphqlOperation(listClubAttendances, {
          nextToken,
          limit: 1000
        })
      );
  
      const currentPage = attendanceResult.data.listClubAttendances;
      allAttendances.push(...currentPage.items);
      nextToken = currentPage.nextToken;
    } while (nextToken);
  
    const attendanceMap = new Map();
  
    allAttendances.forEach(attendance => {
      if (attendance._deleted) return;
      
      const key = `${attendance.userID}-${attendance.opportunityID}-${attendance.recordedDate}`;
      if (!attendanceMap.has(key)) {
        attendanceMap.set(key, []);
      }
      attendanceMap.get(key).push(attendance);
    });
  
    const duplicates = Array.from(attendanceMap.entries())
      .filter(([_, attendances]) => attendances.length > 1)
      .map(([key, attendances]) => {
        const [userID, opportunityID, recordedDate] = key.split('-');
        return {
          userID,
          opportunityID,
          recordedDate,
          count: attendances.length,
          records: attendances.map(att => ({
            id: att.id,
            attendanceMark: att.attendanceMark,
            recordedByID: att.recordedByID,
            createdAt: att.createdAt
          }))
        };
      });
  
    return {
      totalDuplicateSets: duplicates.length,
      totalDuplicateRecords: duplicates.reduce((sum, dup) => sum + dup.count, 0),
      duplicateDetails: duplicates.sort((a, b) => b.count - a.count)
    };
  }

  async function findAwardedBadgesWithoutOpportunities() {
    let nextToken = null;
    const allAwardedBadges = [];
  
    do {
      const awardedBadgesResult = await API.graphql(
        graphqlOperation(listAwardedBadges, { 
          nextToken, 
          limit: 1000 
        })
      );
  
      const currentPage = awardedBadgesResult.data.listAwardedBadges;
      allAwardedBadges.push(...currentPage.items);
      
      nextToken = currentPage.nextToken;
    } while (nextToken);
  
    const opportunityIDs = new Set(
      allAwardedBadges
        .filter(badge => badge.opportunityID)
        .map(badge => badge.opportunityID)
    );
  
    const existingOpportunityIDs = new Set(
      allAwardedBadges
        .filter(badge => badge.Opportunity && badge.Opportunity.id)
        .map(badge => badge.Opportunity.id)
    );
  
    const missingOpportunityIDs = [...opportunityIDs].filter(
      id => !Array.from(existingOpportunityIDs).includes(id)
    );
  
    const badgesWithMissingOpportunities = allAwardedBadges.filter(
      badge => missingOpportunityIDs.includes(badge.opportunityID)
    );
  
    const missingOpportunityIDSummary = missingOpportunityIDs.map(opportunityID => {
      const relatedBadges = badgesWithMissingOpportunities.filter(
        badge => badge.opportunityID === opportunityID
      );
  
      return {
        opportunityID,
        count: relatedBadges.length,
        userIDs: [...new Set(relatedBadges.map(badge => badge.userID))],
        badgeDetails: relatedBadges.map(badge => ({
          badgeId: badge.id,
          userID: badge.userID,
          dateAwarded: badge.dateAwarded
        }))
      };
    });
  
    return {
      totalUniqueMissingOpportunityIDs: missingOpportunityIDs.length,
      totalBadgesWithMissingOpportunities: badgesWithMissingOpportunities.length,
      missingOpportunityDetails: missingOpportunityIDSummary
    };
  }

  const DuplicateAttendanceDisplay = ({ duplicateData }) => {
    if (!duplicateData) return <Text>Loading...</Text>;

    return (
      <View>
        <View style={styles.summaryContainer}>
          <Text style={styles.summaryText}>
            Total Sets of Duplicates: {duplicateData.totalDuplicateSets}
          </Text>
          <Text style={styles.summaryText}>
            Total Duplicate Records: {duplicateData.totalDuplicateRecords}
          </Text>
        </View>

        {duplicateData.duplicateDetails.map((detail, index) => (
          <View key={`${detail.userID}-${detail.opportunityID}-${detail.recordedDate}`} 
                style={styles.detailContainer}>
            <Text style={styles.detailHeader}>
              Duplicate Set {index + 1} ({detail.count} records)
            </Text>
            <View style={styles.detailContent}>
              <Text>User ID: {detail.userID}</Text>
              <Text>Opportunity ID: {detail.opportunityID}</Text>
              <Text>Date: {detail.recordedDate}</Text>
              
              <Text style={styles.subHeader}>Individual Records:</Text>
              {detail.records.map((record, recordIndex) => (
                <View key={record.id} style={styles.recordContainer}>
                  <Text>Record {recordIndex + 1}:</Text>
                  <Text style={styles.indentedText}>ID: {record.id}</Text>
                  <Text style={styles.indentedText}>Mark: {record.attendanceMark}</Text>
                  <Text style={styles.indentedText}>Recorded By: {record.recordedByID}</Text>
                  <Text style={styles.indentedText}>Created: {new Date(record.createdAt).toLocaleString()}</Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    );
  };

  const renderOpportunityDetails = () => {
    if (state.isLoading) return <Text>Loading...</Text>;
    if (state.error) return <Text style={styles.errorText}>Error: {state.error}</Text>;
    if (!state.opportunityData) return <Text>No data available</Text>;

    return (
      <View>
        <View style={styles.summaryContainer}>
          <Text style={styles.summaryText}>
            Total Missing Opportunity IDs: {state.opportunityData.totalUniqueMissingOpportunityIDs}
          </Text>
          <Text style={styles.summaryText}>
            Total Badges with Missing Opportunities: {state.opportunityData.totalBadgesWithMissingOpportunities}
          </Text>
        </View>

        {state.opportunityData.missingOpportunityDetails.map((detail, index) => (
          <View key={detail.opportunityID} style={styles.detailContainer}>
            <Text style={styles.detailHeader}>Opportunity {index + 1}</Text>
            <View style={styles.detailContent}>
              <Text>ID: {detail.opportunityID}</Text>
              <Text>Badge Count: {detail.badgeCount}</Text>
              <Text>Unique Users: {detail.uniqueUserCount}</Text>
              <CollapsibleSection
                title={`User IDs (${detail.userIDs.length})`}
                isVisible={false}
                onToggle={() => {}}
              >
                <Text>{detail.userIDs.join(', ')}</Text>
              </CollapsibleSection>
            </View>
          </View>
        ))}
      </View>
    );
  };



  async function findMissingAttendanceIDs() {
    let nextToken = null;
    const allAttendances = [];
  
    do {
      const attendanceResult = await API.graphql(
        graphqlOperation(listClubAttendances, {
          nextToken,
          limit: 1000
        })
      );
  
      const currentPage = attendanceResult.data.listClubAttendances;
      allAttendances.push(...currentPage.items);
      nextToken = currentPage.nextToken;
    } while (nextToken);
  
    // Filter for non-deleted attendances that don't have an Opportunity
    const missingAttendances = allAttendances
      .filter(attendance => !attendance._deleted && attendance.opportunityID && !attendance.Opportunity)
      .map(attendance => ({
        id: attendance.id,
        opportunityID: attendance.opportunityID,
        userID: attendance.userID,
        recordedDate: attendance.recordedDate
      }))
      .sort((a, b) => a.id.localeCompare(b.id));
  
    return missingAttendances;
  }


  const MissingAttendanceList = ({ data }) => {
    if (!data) return <Text>Loading...</Text>;
    if (data.length === 0) return <Text>No missing attendances found</Text>;
  
    return (
      <View>
        <View style={styles.summaryContainer}>
          <Text style={styles.summaryText}>
            Total Missing Attendances: {data.length}
          </Text>
        </View>
        <View style={styles.listContainer}>
          {data.map((attendance, index) => (
            <Text key={attendance.id} style={styles.listItem}>
              ID: {attendance.id}, OpportunityID: {attendance.opportunityID},
            </Text>
          ))}
        </View>
      </View>
    );
  };


  const renderAttendanceOpportunityDetails = () => {
    if (state.isLoading) return <Text>Loading...</Text>;
    if (state.error) return <Text style={styles.errorText}>Error: {state.error}</Text>;
    if (!state.missingOpportunityAttendances) return <Text>No data available</Text>;

    const data = state.missingOpportunityAttendances;

    return (
      <View>
        <View style={styles.summaryContainer}>
          <Text style={styles.summaryText}>
            Total Missing Opportunity IDs: {data.totalMissingOpportunityIDs}
          </Text>
          <Text style={styles.summaryText}>
            Total Affected Attendances: {data.totalAffectedAttendances}
          </Text>
        </View>

        {data.details.map((detail, index) => (
          <View key={detail.opportunityID} style={styles.detailContainer}>
            <Text style={styles.detailHeader}>
              Missing Opportunity {index + 1}
            </Text>
            <View style={styles.detailContent}>
              <Text>Opportunity ID: {detail.opportunityID}</Text>
              <Text>Affected Attendances: {detail.count}</Text>
              <Text>Unique Users: {detail.userIDs.length}</Text>
              
              <CollapsibleSection
                title={`Attendance Details (${detail.attendanceDetails.length})`}
                isVisible={false}
                onToggle={() => {}}
              >
                {detail.attendanceDetails.map((attendance, idx) => (
                  <View key={attendance.id} style={styles.recordContainer}>
                    <Text>Attendance {idx + 1}:</Text>
                    <Text style={styles.indentedText}>ID: {attendance.id}</Text>
                    <Text style={styles.indentedText}>User ID: {attendance.userID}</Text>
                    <Text style={styles.indentedText}>Date: {attendance.recordedDate}</Text>
                    <Text style={styles.indentedText}>Mark: {attendance.attendanceMark}</Text>
                  </View>
                ))}
              </CollapsibleSection>
            </View>
          </View>
        ))}
      </View>
    );
  };

    return (
      <PageBackground isLoading={state.isLoading}>
      <CollapsibleSection 
          title={'Opportunity Data'} 
          isVisible={state.isOpportunitySectionVisible} 
          onToggle={() => setState(prev => ({
              ...prev,
              isOpportunitySectionVisible: !prev.isOpportunitySectionVisible
          }))}
      >
          {renderOpportunityDetails()}
      </CollapsibleSection>

      <CollapsibleSection 
          title={'Club Attendance Duplicates'} 
          isVisible={state.isAttendanceSectionVisible} 
          onToggle={() => setState(prev => ({
              ...prev,
              isAttendanceSectionVisible: !prev.isAttendanceSectionVisible
          }))}
      >
          <DuplicateAttendanceDisplay 
              duplicateData={state.duplicateAttendanceData} 
          />
      </CollapsibleSection>


<CollapsibleSection
title={'attendance opp '} 
isVisible={state.isAttendanceSectionVisible} 
onToggle={() => setState(prev => ({
    ...prev,
    isAttendanceSectionVisible: !prev.isAttendanceSectionVisible
}))}>
      {renderAttendanceOpportunityDetails()}
      </CollapsibleSection>




<CollapsibleSection title='IDS to delete'

isVisible={state.isAttendanceSectionVisible} 
onToggle={() => setState(prev => ({
    ...prev,
    isAttendanceSectionVisible: !prev.isAttendanceSectionVisible

  }))}>
<MissingAttendanceList data={missingAttendances} />
</CollapsibleSection>

      
  </PageBackground>


    )
}

export default DataIntegrityCheck
const styles = StyleSheet.create({
  summaryContainer: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f5f5f5',
    borderRadius: 8
  },
  summaryText: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 8
  },
  detailContainer: {
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    overflow: 'hidden'
  },
  detailHeader: {
    padding: 10,
    backgroundColor: '#f0f0f0',
    fontWeight: '600'
  },
  detailContent: {
    padding: 15
  },
  subHeader: {
    fontWeight: '600',
    marginTop: 10,
    marginBottom: 5
  },
  recordContainer: {
    marginLeft: 10,
    marginTop: 10,
    paddingLeft: 10,
    borderLeftWidth: 2,
    borderLeftColor: '#ddd'
  },
  indentedText: {
    marginLeft: 10
  },
//   detailContainer: {
//     marginVertical: 10,
//     padding: 10,
//     borderWidth: 1,
//     borderColor: '#ddd'
// }
});

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import TimelinesRootScreen from "../screens/timelinesRoot";
import TimelinesDetail from "../screens/timelinesDetail";
import OpportunityDetail from "../screens/opportunityDetail";
import FlexibleLearningHeatMap from "../screens/FlexibleLearningHeatMap";
import PhotoPopUp from "../screens/photoPopUp";
import { VibrateOnce } from "../functions/vibration";
import GroupgingData2List from "../screens/groupingData2List";
import GroupingData2List from "../screens/groupingData2List";
import GroupingData3List from "../screens/groupingData3List";
import GroupingData1List from "../screens/groupingData1List";
import CriteriaPopUp from "../screens/CriteriaPopUp";
import ActivitiesRoot from "../screens/ActivitiesRoot";
import ActivitiesSelection from "../screens/ActivitiesSelection";
import ActivitiesRegister from "../screens/ActivitiesRegister";
import ActivitiesPreviousRegister from "../screens/ActivitiesPreviousRegister";
import ChangeDatePopUp from "../screens/ChangeDatePopUp";
import AdminRoot from "../screens/AdminRoot";
import AdminUsers from "../screens/AdminUsers";
import { View,Text } from "../constants/themedViewAndText";
import { Feather } from "@expo/vector-icons";
import AdminUserDetails from "../screens/AdminUserDetails";
import { BoldText } from "../constants/styledText";
import AwardNewBadge from "../screens/AwardNewBadge";
import AdminBadges from "../screens/AdminBadges";
import AdminBadgeDetails from "../screens/AdminBadgeDetails";
import AdminBadgesAuthorise from "../screens/AdminBadgesAuthorise";
import AdminActivities from "../screens/AdminActivities";
import AdminActivityDetails from "../screens/AdminActivityDetails";
import DataIntegrityCheck from "../screens/DataIntegrityCheck";
import AdminAnalysisPupilEngagement from "../screens/AdminAnalysisPupilEngagement";

const AdminStack = createNativeStackNavigator();

export default function AdminStackNavigator() {
  const colorScheme = useColorScheme();
  return (
    <AdminStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colours[colorScheme].background,
        },
        headerShown: false,
        headerTitleStyle: { fontFamily: "avenir-next" },
        headerShadowVisible: false,
      }}>
      <AdminStack.Screen
        name="AdminRoot"
        component={AdminRoot}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
      
        headerTitle:()=>null, 
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
<AdminStack.Screen
        name="AdminUsers"
        component={AdminUsers}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
        

        }}
      />
      <AdminStack.Screen
        name="AdminUserDetails"
        component={AdminUserDetails}
        options={{
          headerShown: true, //AJL: this is how you show a back button for some of the screens!
          headerTitle:()=>null,
        }}
      />

<AdminStack.Screen
        name="AwardNewBadge"
        component={AwardNewBadge}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <AdminStack.Screen
        name="AdminBadges"
        component={AdminBadges}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <AdminStack.Screen
        name="AdminBadgesAuthorise"
        component={AdminBadgesAuthorise}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
         <AdminStack.Screen
        name="AdminActivities"
        component={AdminActivities}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <AdminStack.Screen
        name="AdminBadgeDetails"
        component={AdminBadgeDetails}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <AdminStack.Screen
        name="AdminActivityDetails"
        component={AdminActivityDetails}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <AdminStack.Screen
        name="AdminDataIntegrityCheck"
        component={DataIntegrityCheck}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />
      <AdminStack.Screen
        name="AdminAnalysisPupilEngagement"
        component={AdminAnalysisPupilEngagement}
        options={{
          headerShown: false,
          // headerTransparent: true,
          // headerShadowVisible: false,
          // title: "",
          // headerTintColor: Colours[colorScheme].buttons,
          // headerStyle: { backgroundColor: "transparent" },
          // headerStyle: {
          //   backgroundColor: Colors[colorScheme].almostBackground,

          // },
        }}
        listeners={() => ({
          transitionStart: () => {
            VibrateOnce();
          },
        })}
      />

<AdminStack.Group
        screenOptions={{
          presentation: "modal",
          // presentation: 'transparentModal'
          // presentation:'containedTransparentModal'
        }}>
        {/* <TimeslineStack.Screen name="FlexibleLearningHeatMap" component={FlexibleLearningHeatMap} /> */}
        
        <AdminStack.Screen
        name="ChangeDatePopUp"
        // initialParams={{ opportunityID:opportunityID}}
        component={ChangeDatePopUp}
        options={{
          headerShown: false, //AJL: this is how you show a back button for some of the screens!
          // headerShadowVisible: true,
          // headerTitle: () => <ScreenTitleTimelinesMobile />,
        }}
      />
        
        
        
      </AdminStack.Group>


      
    </AdminStack.Navigator>
  );
}

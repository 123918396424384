import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import FlexibleLearning from "../screens/FlexibleLearning";
import Badges from "../screens/Badges";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import BadgeIcon from "../../assets/icons/Badge";
import { Image } from "react-native";
import { Feather, Ionicons } from "@expo/vector-icons";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import IndependentLearningIcon from "../../assets/icons/IndependentLearning";
import { VibrateOnce } from "../functions/vibration";
import TrainingIcon from "../../assets/icons/Training";
import SettingsScreen from "../screens/settingsRoot";
import Training from "../screens/Training";
import ActivitiesSelectionAut1 from "../screens/ActivitiesSelectionList";
import ActivitiesSelection from "../screens/ActivitiesSelection";
import ActivitiesSelectionList from "../screens/ActivitiesSelectionList";
import ActivitiesCreateSession from "../screens/ActivitiesCreateSession";
import ActivitiesPreviousSessions from "../screens/ActivitiesPreviousSessions";
import ActivitiesPreviousSessionsStackNavigator from "./activitiesPreviousSessionsStack";
import { FontAwesome5 } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
const Tab = createMaterialTopTabNavigator();

const RegisterTabNavigator = ({ opportunityID, name }) => {
  const colorScheme = useColorScheme();





  return (
    <>
      <Tab.Navigator
     
     
        initialRouteName="Create New Session"
        screenOptions={{
          swipeEnabled:false,
          tabBarStyle: {
            height: 70,
            backgroundColor: Colours[colorScheme].background,
            margin: 0,
            padding: 0,
            justifyContent: "flex-start",
          },
        
          tabBarActiveTintColor: Colours.palette.secondaryGreen, //icon and text label
          tabBarInactiveTintColor: Colours[colorScheme].edging, //icon and text label
          tabBarIconStyle: { margin: 0, padding: 0, height: 30, width: 30 },
          tabBarBounces: true,
          tabBarIndicatorStyle: { backgroundColor: Colours.palette.secondaryGreen },
          tabBarLabelStyle: { color: Colours[colorScheme].textLessEmphasised, fontSize: 12, fontFamily: "avenir-next", margin: 0, padding: 0 },
          // tabBarShowLabel:false
        }}>
        <Tab.Screen
          name="Create New Session"
          component={ActivitiesCreateSession}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ opportunityID: opportunityID, name: name }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: "Create New Session",
            // tabBarShowLabel: false,
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  //  width: 50, height: 50,
                  // padding:10,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <BadgeIcon color={color} /> */}
                {/* <Feather name="calendar" size={24} color={color} /> */}
                {/* <FontAwesome5 name="calendar-check" size={24} color={color} /> */}
                <MaterialCommunityIcons name="calendar-check-outline" size={24} color={color}  />
              </View>
            ),
          }}
        />

        <Tab.Screen
          name="Previous Sessions Stack"
          component={ActivitiesPreviousSessionsStackNavigator}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ opportunityID: opportunityID }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: "Previous Sessions",
            // tabBarShowLabel: false,
            
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  // flexDirection: "row",
                  // flex:1,
                  // width:220,
                  // width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <Feather name="calendar" size={24} color={color} /> */}
                <MaterialCommunityIcons name='calendar-search' size={24} color={color} />
                {/* <Text style={{ paddingHorizontal:20}}>PREVIOUS SESSIONS</Text> */}
              </View>
            ),
          }}
        />
      </Tab.Navigator>
    </>
  );
};

export default RegisterTabNavigator;

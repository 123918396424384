import React from "react";
import { Pressable, ScrollView, StyleSheet } from "react-native";
import { Feather } from "@expo/vector-icons";
import {VibrateOnce} from "../functions/vibration"; 
import {BoldText} from "../constants/styledText";
import Colours from "../constants/colours";
import useColorScheme from "../hooks/useColorScheme";
import { View, Text } from "../constants/themedViewAndText";
import BackButton from "./BackButton";
import ActivityIndicatorAnimation from "./ActivityIndicator";

interface PageBackground {
    isLoading: boolean;
    children: React.ReactNode;
  
  }

const PageBackground: React.FC<PageBackground> = ({isLoading,children}) => {
      const colorScheme = useColorScheme();
    return (
<View style={{ flex: 1, backgroundColor: Colours[colorScheme].background }}>
      <View style={{ backgroundColor: Colours[colorScheme].background, height: 40 }}>
        <BackButton />
      </View>
      <ScrollView contentContainerStyle={{ backgroundColor: Colours[colorScheme].background, flexGrow: 1 }}>
        {isLoading ? (
          <ActivityIndicatorAnimation />
        ) : (
          <View style={{ flex: 1, width: "100%", padding: 20,
          backgroundColor: Colours[colorScheme].practicallyBackground, 
          borderRadius: 10, alignItems: "center",flexDirection: "column", }}>
              {children}

</View>)}

</ScrollView>
</View>


    )}

    export default PageBackground;
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { View, Text } from "../constants/themedViewAndText";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import React, { useContext } from "react";
import { OrganisationContext, UserContext } from "../hooks/Contexts";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";
import ActivitiesSelectionList from "../screens/ActivitiesSelectionList";
import FlexibleLearningIcon from "../../assets/icons/FlexibleLearning";
import { useWindowDimensions } from "react-native";
import { getCurrentTerm } from "../functions/getCurrentTerm";

const Tab = createMaterialTopTabNavigator();

const TermTabNavigator = () => {
  const colorScheme = useColorScheme();
  const {width} = useWindowDimensions();
  
  const {specialDates } = useContext(OrganisationContext);



console.log('width', width)

  return (
    <>
      <Tab.Navigator
        initialRouteName={getCurrentTerm(specialDates)}
        screenOptions={{
          swipeEnabled:false,
          tabBarStyle: { height: 70, backgroundColor: Colours[colorScheme].background, margin: 0, padding: 0, justifyContent: "flex-start" },
          tabBarActiveTintColor: Colours.palette.secondaryGreen, //icon and text label
          tabBarInactiveTintColor: Colours[colorScheme].edging, //icon and text label
          tabBarIconStyle: { margin: 0, padding: 0, height: 30, width: 30 },
          tabBarBounces: true,
          tabBarIndicatorStyle: { backgroundColor: Colours.palette.secondaryGreen },
          tabBarLabelStyle: { color: Colours[colorScheme].textLessEmphasised, fontSize: 12, fontFamily: "avenir-next", margin: 0, padding: 0 },
          // tabBarShowLabel:false
        }}>
        <Tab.Screen
          name= "Autumn 1"
          component={ActivitiesSelectionList}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ term: "Autumn 1" }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: width<600? "Aut 1": "Autumn 1",
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  //  width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <FlexibleLearningIcon color={color} />
                {/* <BadgeIcon color={color} /> */}
                {/* <Feather name="calendar" size={24} color={color} /> */}
                {/* <MaterialCommunityIcons name="calendar-blank" size={24} color={color} /> */}
              </View>
            ),
          }}
        />

        <Tab.Screen
          name="Autumn 2"
          component={ActivitiesSelectionList}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ term: "Autumn 2" }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: width<600? "Aut 2": "Autumn 2",
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  // width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <MaterialCommunityIcons name="calendar-blank" size={24} color={color} /> */}
                <FlexibleLearningIcon color={color} />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Spring 1"
          component={ActivitiesSelectionList}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ term: "Spring 1" }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: width<600? "Spr 1": "Spring 1",
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  // width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <MaterialCommunityIcons name="calendar-blank" size={24} color={color} /> */}
                <FlexibleLearningIcon color={color} />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Spring 2"
          component={ActivitiesSelectionList}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ term: "Spring 2" }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: width<600? "Spr 2": "Spring 2",
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  // width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <MaterialCommunityIcons name="calendar-blank" size={24} color={color} /> */}
                <FlexibleLearningIcon color={color} />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Summer 1"
          component={ActivitiesSelectionList}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ term: "Summer 1" }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: width<600? "Sum 1": "Summer 1",
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  // width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <MaterialCommunityIcons name="calendar-blank" size={24} color={color} /> */}
                <FlexibleLearningIcon color={color} />
              </View>
            ),
          }}
        />
        <Tab.Screen
          name="Summer 2"
          component={ActivitiesSelectionList}
          listeners={() => ({
            tabPress: () => {
              VibrateOnce();
            },
          })}
          initialParams={{ term: "Summer 2" }}
          options={{
            // title: "Badges\n", //THIS PUT AN EXTRA LINE UNDER THE WORK TO BALANCE OUT WITH LONGER NAMES
            title: width<600? "Sum 2": "Summer 2",
            tabBarIcon: ({ color }) => (
              <View
                style={{
                  // width: 50, height: 50,
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {/* <MaterialCommunityIcons name="calendar-blank" size={24} color={color} /> */}
                <FlexibleLearningIcon color={color} />
              </View>
            ),
          }}
        />
      </Tab.Navigator>
    </>
  );
};

export default TermTabNavigator;

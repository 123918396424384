import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import PageBackground from "../components/PageBackground";
import { View, Text } from "../constants/themedViewAndText";
import PupilEngagementTable from "../components/EngagementTable";

export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $nextToken: String) {
    listUsers(filter: $filter, limit: 1000, nextToken: $nextToken) {
      items {
        id
        surname
        firstName
        preferredFirstName
        preferredSurname
        isActive
        type
        Enrolments {
          items {
            groupingData1
            groupingData2
            groupingData3
          }
        }
        ClubAttendanceMarks {
          items {
            id
            recordedDate
          }
        }
        AwardedBadges {
          items {
            id
            scoringData3Value
            dateAwarded
          }
        }
      }
      nextToken
    }
  }
`;

const AdminAnalysisPupilEngagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const fetchAllUsers = async () => {
    console.log("Fetching users started");
    let allUsers = [];
    let nextToken = null;
    
    try {
      do {
        const response = await API.graphql(
          graphqlOperation(listUsers, {
            filter: {
              isActive: { eq: true },
              type:{eq: 'CHILD'}
            },
            nextToken: nextToken,
          })
        );

        const newUsers = response.data.listUsers.items;
        nextToken = response.data.listUsers.nextToken;
        allUsers = [...allUsers, ...newUsers];
        
        console.log(`Fetched batch of ${newUsers.length} users. Next token: ${nextToken}`);
      } while (nextToken);

      console.log(`Total users fetched: ${allUsers.length}`);
      setUsers(allUsers);
      
    } catch (err) {
      console.error("Error fetching users:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <PageBackground isLoading={isLoading}>
      <PupilEngagementTable users={users} />
    </PageBackground>
  );
};

export default AdminAnalysisPupilEngagement;
import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { Feather } from "@expo/vector-icons";
import { VibrateOnce } from "../functions/vibration";
import { BoldText } from "../constants/styledText";
import Colours from "../constants/colours";
import useColorScheme from "../hooks/useColorScheme";
import { View, Text } from "../constants/themedViewAndText";

interface CollapsibleSectionProps {
  title: string;
  isVisible: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  isVisible,
  onToggle,
  children,
}) => {
  const colorScheme = useColorScheme();
  return (
    <View style={{ width: "100%", backgroundColor:'transparent' }}>
      <Pressable
        onPress={() => {
          VibrateOnce();
          onToggle();
        }}
        style={[
          styles.header,
          {
            backgroundColor: Colours[colorScheme].almostBackground,
          },
        ]}>
        <Feather name={isVisible ? "chevron-down" : "chevron-right"} color={Colours.palette.logoGreen} size={30} />
        <BoldText style={{ color: Colours[colorScheme].text }}>{title}</BoldText>
      </Pressable>

      {isVisible && (
        <View
          style={[
            styles.content,
            {
              backgroundColor: Colours[colorScheme].background,
            },
          ]}>
          {children}
        </View>
      )}

      {!isVisible && (
        <View
          style={{
            marginBottom: 10,
            padding: 10,
            borderBottomLeftRadius: 10,
            // height: "60%",
            borderBottomRightRadius: 10,
            width: "100%",
            backgroundColor: Colours[colorScheme].background,
          }}></View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  content: {
    marginBottom: 10,
    padding: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    width: "100%",
  },
});

export default CollapsibleSection;

import { Pressable, StyleSheet, Platform } from "react-native";
import { View, Text } from "../constants/themedViewAndText";
import React, { useContext, useState } from "react";
import { UserContext, AllUsersContext } from "../hooks/Contexts";
import useColorScheme from "../hooks/useColorScheme";
import Colours from "../constants/colours";
import { Feather } from "@expo/vector-icons";
import { BoldText } from "../constants/styledText";
import { Auth } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react-native";
import AchievementsTabNavigator from "../navigation/achievementsTabs";
import PointsDashboard from "../components/PointsDashboard";
import * as WebBrowser from "expo-web-browser";
import { VibrateOnce } from "../functions/vibration";
import * as Application from "expo-application";
import AdminStackNavigator from "../navigation/adminStack";
import RecognitionLogoIndentIcon from "../../assets/icons/RecognitionLogoIndent";
import ActionButton from "../components/ActionButton";
import { useNavigation } from "@react-navigation/native";
import * as Updates from 'expo-updates';
const SettingsScreen = () => {
  const navigation = useNavigation()
  const colorScheme = useColorScheme();
  const userDetailsFromUserContext = useContext(UserContext);
  const allUserDetailsFromAllUsersContext = useContext(AllUsersContext);
  // const version = Platform.OS === "web" ? "web browser version" : Application.nativeApplicationVersion;

console.log ('Updates.manifest',Updates)

   const version = Platform.OS === "web" ? "2.5.8w" : Application.nativeApplicationVersion;

  const type = userDetailsFromUserContext.type

  const NavigateToAdminStack = () => {
    navigation.navigate("AdminStack", { });
  };


  // Create a new Date object
  var currentDate = new Date();

  // Get the current year
  var currentYear = currentDate.getFullYear();

  const [awardedBadgesData, setAwardedBadgesData] = useState([]);
  const { signOut } = useAuthenticator();

  function handleHelpPress() {
    VibrateOnce();
    WebBrowser.openBrowserAsync("https://support.recognition.org.uk");
  }
  function handleBugPress() {
    VibrateOnce();
    WebBrowser.openBrowserAsync("https://forms.office.com/Pages/ResponsePage.aspx?id=4i86_GOrvEutrKyNOzdxNAZG0OuFsM1Ajh1DmdVoPvxUMkxYUEVaV1JYRUFXSDFFTDdETlRZQTEwTy4u");
  }

  function handleDeletePress() {
    VibrateOnce();
    WebBrowser.openBrowserAsync("https://forms.office.com/r/BH8gx43W8i");
  }
  return (
    <View style={{ backgroundColor: Colours[colorScheme].background, flex: 1 }}>
      <View style={{ flex: 1, borderRadius: 10, backgroundColor: Colours[colorScheme].almostBackground, alignItems: "center", justifyContent: "center" }}>
        
        {type === 'ADMIN' ?<ActionButton icon={"lock"} text={"Administration"} onPressFunction={NavigateToAdminStack} />:null}
        
        <ActionButton icon={"help-circle"} text={"Get support"} onPressFunction={handleHelpPress} />
        <ActionButton icon={"user-x"} text={"Delete account"} onPressFunction={handleDeletePress} />
        <ActionButton icon={"frown"} text={"Report a bug"} onPressFunction={handleBugPress} />
        <ActionButton icon={"log-out"} text={"Sign out"} onPressFunction={signOut} />

        

        <View style={{ backgroundColor: "transparent", marginTop: 20, flexDirection: "row" }}>
          <View style={{ height: 11, width: 11, alignSelf: "center", marginRight: 5, backgroundColor: "transparent" }}>
            <RecognitionLogoIndentIcon color={Colours[colorScheme].textLessEmphasised} />
          </View>
          <Text style={{ color: Colours[colorScheme].textLessEmphasised }}>RECOGNITION ({version})</Text>
        </View>
        <Text style={{ fontSize: 9, color: Colours[colorScheme].textLessEmphasised }}>© Carr Manor Community School {currentYear}</Text>
      </View>
    </View>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({});
